<template>
  <v-app dark class="backgroundImage">
    <nav-bar></nav-bar>
    <transition :name="$route.name" mode="out-in">
      <router-view></router-view>
    </transition>

    <foooter></foooter>
    <sub-footer></sub-footer>
  </v-app>
</template>

<script>
import NavBar from './components/NavBar.vue'
import SubFooter from './components/SubFooter.vue'
import Foooter from './components/Footer.vue'
export default {
  name: 'App',
  components: {
    NavBar,
    SubFooter,
    Foooter,
  },
  created() {
    // if (this.$route.name == 'map') {
    //   console.log('map')
    //   return
    // }
    // console.log('doing stuff...', this.$route.name, this.$route.params)
    this.$store.selectedStripeType = this.$route.params.stripeType || 's'
    this.$store.selectedRegion = this.$route.params.region || 'globe'
    this.$store.selectedCountry = this.$route.params.country || ''
    this.$store.selectedLocation = this.$route.params.location || ''

    if (this.$store.selectedLocation === 'kiev') {
      // We want to do a manual redirect here, so we don't just get a 404
      // Renaming Kiev to Kyiv is a fine political statement to make.
      // Removing Kiev from the site entirely is not.
      this.$store.selectedLocation = 'kyiv'
    }

    if (
      this.$route.name !== 'map' &&
      this.$route.name !== 'faq' &&
      this.$route.name !== 'support' &&
      this.$route.name !== 'showcase'
    ) {
      this.$router.push({
        name: 'stripes',
        params: {
          stripeType: this.$store.selectedStripeType,
          region: this.$store.selectedRegion,
          country: this.$store.selectedCountry,
          location: this.$store.selectedLocation,
        },
      })
    }
  },
}
</script>

<style scoped>
.backgroundImage {
  /* Image downloaded from www.subtlepatterns.com */
  /* https://www.toptal.com/designers/subtlepatterns/prism/ */
  background-image: url('@/assets/img/prism.png');
}
.stripes-enter-active,
.stripes-leave-active,
.faq-enter-active,
.faq-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}
.faq-enter,
.stripes-leave-to {
  opacity: 0;
  transform: translateX(3%);
}
.stripes-enter,
.faq-leave-to {
  opacity: 0;
  transform: translateX(-3%);
}
</style>

<!-- <style>
#bbox-root {
  display: none;
}
</style> -->
