<template lang="html">
  <div class="gallery-container">
    <gallery :items="images" :origin="origin" @close="origin = null" />
    <div
      class="image"
      v-for="(image, imageIndex) in images"
      :key="image.href"
      @click="origin = imageIndex"
      :style="{
        backgroundImage: 'url(' + image.href + ')',
      }"
    ></div>
  </div>
</template>

<script>
import 'vue-blueimp-gallery/dist/vue-blueimp-gallery.css'
import gallery from 'vue-blueimp-gallery'

const rand = (n) => 0 | (Math.random() * n)

export function shuffle(t) {
  let last = t.length
  let n
  while (last > 0) {
    n = rand(last)
    swap(t, n, --last)
  }
}

function swap(t, i, j) {
  let q = t[i]
  t[i] = t[j]
  t[j] = q
  return t
}

export default {
  data() {
    return {
      origin: null,
      images: [],
      options: {},
    }
  },
  computed: {},
  components: {
    gallery,
  },
  mounted() {
    const images = []
    // images.push({
    //   href: '/showcase/beer.png',
    //   description:
    //     'The Pueblo Vida brewing company in Arizona made a special edition beer called Threshold and designed the cans to look like the warming stripes.',
    // })
    images.push({
      href: '/showcase/bridge.png',
      description:
        'In 2022, the Sachsen Bridge in Leipzig was painted with warming stripes through crowdfunding.',
    })
    images.push({
      href: '/showcase/bt_tower.jpg',
      description:
        'The iconic BT Tower lights up the London skyline for Show Your Stripes Day June 2024',
    })
    images.push({
      href: '/showcase/bus.png',
      description:
        'The climate stripes adorn a gas-powered Reading Buses bus, launched in July 2022. Other modes of transport have also been adapted to feature the stripes, including a food delivery truck, a Tesla car and an all-terrain bicycle. ',
    })
    images.push({
      href: '/showcase/common_grace.png',
      description:
        'Australian Senator Patrick Dobson is presented with a knitted warming stripes scarf by the Common Grace group.',
    })
    images.push({
      href: '/showcase/COP.png',
      description:
        'The warming stripes on display at the UN COP25 conference in Madrid in 2019.',
    })
    // images.push({
    //   href: '/showcase/copenhagen.png',
    //   description:
    //     'In 2021, the climate stripes were used for interactive light shows in Copenhagen (pictured) and Berlin.',
    // })
    images.push({
      href: '/showcase/cycling.png',
      description:
        'One of many cycling events organised by Cycling4Climate in Utrecht, Netherlands.',
    })
    images.push({
      href: '/showcase/dover.png',
      description:
        'The UK warming stripes were projected onto the White Cliffs of Dover in June 2023 to mark Show Your Stripes day.',
    })
    images.push({
      href: '/showcase/ecotricity.jpg',
      description:
        'Stripes projected onto Ecotricity’s wind turbine near Stroud, UK – June 2024',
    })
    images.push({
      href: '/showcase/greta_thunberg.png',
      description:
        'Teenage climate activist Greta Thunberg emblazoned the climate stripes across ‘The Climate Book’, her handbook for ways to change the world.',
    })
    // images.push({
    //   href: '/showcase/jersey.png',
    //   description:
    //     'Unveiled in 2020, the stripes have been used on Jersey to create a giant mural showing temperature changes on the island.',
    // })
    images.push({
      href: '/showcase/london_fashion_week.png',
      description:
        'In 2022, sustainable couture designer, Tammam, turned the iconic stripes into an eco-friendly clothing range for display at London Fashion Week.',
    })
    images.push({
      href: '/showcase/ozi.jpg',
      description:
        'Ozi:Voice of the Forest animated movie showcases the warming stripes – summer 2024',
    })
    images.push({
      href: '/showcase/pope.png',
      description:
        'The Pope was presented with a warming stripes stole in June 2023. Image copyright: Vatican Media.',
    })
    images.push({
      href: '/showcase/reading_fc.png',
      description:
        'The climate stripes featured on the sleeves of the 2022/2023 home shirt of Reading Football Club. Tom Ince (left) and Shane Long (right) are pictured. The stripes also appeared down the front of the 2022/2023 pink and red away shirt.',
    })
    images.push({
      href: '/showcase/reading_festival.png',
      description:
        'Rock band Enter Shikari displayed the climate stripes at their Reading Festival set in August 2021. ',
    })
    // images.push({
    //   href: '/showcase/tartan.png',
    //   description:
    //     'Tartan t-shirts featuring the climate stripes created by Prickly Thistle in 2022. Other items of clothing featuring the stripes include scarves, jumpers and dresses.',
    // })
    images.push({
      href: '/showcase/tesla.png',
      description:
        'The warming stripes were used to decorate this Tesla car belonging to Mark Hanson from Minnesota.',
    })
    images.push({
      href: '/showcase/tower_of_london.jpg',
      description: 'The Tower of London shows it’s stripes on 21st June 2024',
    })
    // images.push({
    //   href: '/showcase/toenails.png',
    //   description:
    //     'Climate stripes painted on the toenails of New York resident Sonam Velani.',
    // })
    images.push({
      href: '/showcase/vases.png',
      description:
        'Glass artist Cathryn Shilling created a mosaic and several vessels featuring the stripes. In 2022, the striking designs toured the UK as she helped to raise awareness about global warming.',
    })
    images.push({
      href: '/showcase/weather_show.png',
      description:
        'Wes Hohenstein, Chief Meteorologist at US TV station WNCN, used the climate stripes to demonstrate warming on a global scale. Many other news channels have used the graphic to help illustrate rising temperatures, such as CBSN in the US, RTE in Ireland and the BBC and GMB in the UK.',
    })
    shuffle(images)
    this.images = images
  },
}
</script>
<style lang="css" scoped>
.gallery-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  grid-template-rows: auto;
  justify-content: space-between;
  max-height: 90vh;
}
.image {
  cursor: pointer;
  object-fit: contain;
  flex: 1 1 14vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 5px;
}

>>> .control.button.caption-edit,
>>> .control.button.remove {
  display: none;
}
</style>
