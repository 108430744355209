var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-navigation-drawer',{attrs:{"app":""},model:{value:(_vm.drawerOpen),callback:function ($$v) {_vm.drawerOpen=$$v},expression:"drawerOpen"}},[_c('v-list',{attrs:{"three-line":"","subheader":""}},[_c('v-subheader',[_vm._v("Menu")]),_vm._l((_vm.menuItems),function(item){return [_c('v-list-tile',{key:item.name,attrs:{"to":item.route ? item.route : '',"href":item.target ? item.target : '',"target":item.target ? '_blank' : ''}},[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v(_vm._s(item.title))]),_c('v-list-tile-sub-title',[_vm._v(_vm._s(item.desc))])],1)],1),_c('v-divider',{key:item.name})]})],2)],1):_vm._e(),_c('v-toolbar',{attrs:{"height":_vm.nav_bar_height}},[(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-divider',{attrs:{"vertical":""}}):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-toolbar-side-icon',{on:{"click":function($event){_vm.drawerOpen = !_vm.drawerOpen}}}):_vm._e(),_c('v-toolbar-title',{class:{
        'ml-0': _vm.$vuetify.breakpoint.mdAndDown,
        'ml-3': _vm.$vuetify.breakpoint.lgAndUp,
      }},[_c('router-link',{staticClass:"font-weight-medium",class:{
          smalltitle: _vm.$vuetify.breakpoint.mdAndDown,
          title: _vm.$vuetify.breakpoint.lgAndDown,
          'display-1': _vm.$vuetify.breakpoint.lgAndUp,
        },attrs:{"to":"/","tag":"button"}},[_vm._v(" "+_vm._s(_vm.appTitle)+" ")])],1),_c('v-spacer'),_c('v-toolbar-items',{staticClass:"hidden-sm-and-down"},[_vm._l((_vm.menuItems),function(item){return [_c('v-btn',{key:item.name,class:{
            title: _vm.$vuetify.breakpoint.lgAndUp,
            subheading: _vm.$vuetify.breakpoint.lgAndDown,
            menuitem: _vm.$vuetify.breakpoint.mdAndDown,
          },attrs:{"flat":"","to":item.route ? item.route : '',"href":item.target ? item.target : '',"target":item.target ? '_blank' : ''}},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('v-divider',{key:item.name,attrs:{"vertical":""}})]})],2),_c('a',{attrs:{"target":"_blank","rel":"noopener","href":"https://www.reading.ac.uk/planet/"},on:{"click":_vm.linkClicked}},[_c('img',{staticClass:"mx-3 mt-2",attrs:{"alt":"University of Reading logo","src":require("@/assets/img/UoR_Logo.png"),"width":_vm.$vuetify.breakpoint.lgAndUp ? 120 : 80}})])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }