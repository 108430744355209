var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-footer',{class:{
    'pa-4': _vm.$vuetify.breakpoint.mdAndUp,
    'pt-1': _vm.$vuetify.breakpoint.smAndDown,
  },attrs:{"height":_vm.footer_height}},[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":""}},[_c('v-flex',{attrs:{"md6":"","xs12":""}},[_c('div',{class:{
          'text-xs-left body-1 ml-3': _vm.$vuetify.breakpoint.mdAndUp,
          'text-xs-center body-1': _vm.$vuetify.breakpoint.smAndDown,
          'text-xs-left caption': _vm.$vuetify.breakpoint.lg,
        }},[_c('span',{staticClass:"key hidden-sm-and-down"},[_vm._v("Graphics and lead scientist: ")]),_c('span',{staticClass:"key hidden-md-and-up"},[_vm._v("Lead scientist : ")]),_c('a',{attrs:{"target":"_blank","href":"http://www.met.reading.ac.uk/~ed/home/index.php"},on:{"click":_vm.edLinkClicked}},[_vm._v("Ed Hawkins")]),_c('span',{staticClass:"value hidden-lg-and-down"},[_vm._v(", National Centre for Atmospheric Science, University of Reading.")]),_c('span',{staticClass:"value hidden-lg-and-up"},[_vm._v(", NCAS, UoR.")]),_c('span',{staticClass:"value hidden-md-and-down"},[_vm._v(", National Centre for Atmospheric Science, UoR.")])]),_c('div',{class:{
          'text-xs-left ml-3': _vm.$vuetify.breakpoint.mdAndUp,
          'text-xs-center': _vm.$vuetify.breakpoint.smAndDown,
          'text-xs-left caption': _vm.$vuetify.breakpoint.lg,
        }},[_c('span',{staticClass:"key"},[_vm._v("Data: ")]),_c('span',{staticClass:"value hidden-md-and-up"},[_vm._v("Berkeley Earth & ERA5-Land, NOAA, UK Met Office,"),_c('br'),_vm._v(" MeteoSwiss, DWD, SMHI, UoR & ZAMG")]),_c('span',{staticClass:"value hidden-sm-and-down"},[_vm._v("Berkeley Earth & ERA5-Land, NOAA, UK Met Office, MeteoSwiss, DWD, SMHI, UoR & ZAMG")])])]),_c('v-flex',{attrs:{"md4":"","offset-md2":"","xs-12":""}},[_c('div',{class:{
          'text-xs-right subheading mr-4': _vm.$vuetify.breakpoint.mdAndUp,
          'text-xs-center subheading': _vm.$vuetify.breakpoint.smAndDown,
          'text-xs-right caption': _vm.$vuetify.breakpoint.lg,
        }},[_c('span',{staticClass:"key"},[_vm._v("Website: ")]),_c('a',{attrs:{"target":"_blank","href":"https://www.the-iea.org/"},on:{"click":_vm.ieaLinkClicked}},[_c('img',{staticClass:"iea_favicon",attrs:{"src":require("@/assets/IEA_favicon.png"),"height":"30","alt":"Institute for Environmental Analytics logo"}}),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Institute for Environmental Analytics")]),_c('span',{staticClass:"hidden-md-and-up"},[_vm._v("IEA")])])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }