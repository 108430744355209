<template lang="html">
  <div class="wrapper">
    <div id="support-intro">
      <h2>Supporting our Work</h2>
      <p>
        The warming stripes graphics have been created by Professor Ed Hawkins
        to start conversations about our warming world and the risks of climate
        change.
      </p>
      <p>
        These graphics are free to use for any purpose (with appropriate
        credits), but if you would like to make a charitable donation to support
        climate science and education at the University of Reading you can do
        that here.
      </p>
      <p>
        Donations will contribute towards funding a new scholarship scheme for Meteorology students. They will also contribute towards a research project, funding a PhD studentship investigating historic weather records collected in the UK from the 17th to 19th centuries. This three year research project will help test and improve the robustness of some of the world's longest running climate records, with the datasets then freely shared with other climate scientists around the globe.
      </p>
      <p>
        The University of Reading is an independent corporation with charitable
        status (reference X 8145). If you would like to make a gift and are a UK
        taxpayer, your generosity can be boosted further thanks to Gift Aid, at
        no extra cost to you.
      </p>
      <p>Thank you for your support.</p>
    </div>
    <div class="loading" v-if="loading">
      Loading form, please wait
    </div>
    <div id="bbox-root"></div>
  </div>
</template>

<script>
export default {
  data() {
    return { loading: true }
  },
  mounted() {
    // bbox gets added by the script which deals with donation forms.
    /* global bbox */
    bbox.showForm('822e8880-931e-4481-bcf7-9221cd930bfb')

    // Check when the form is actually loaded - i.e. when it appears on the page.
    const setLoadedFunc = () => {
      const el = document.getElementById('mongo-form')
      if (el) {
        this.loading = false
      } else {
        setTimeout(setLoadedFunc, 100)
      }
    }
    setLoadedFunc()
  }
}
</script>
<style lang="css" scoped>
.wrapper {
  height: 100%;
  padding-left: 32px;
  padding-right: 32px;
}

#support-intro {
  padding: 32px 0 0 0;
}

#support-intro h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 8px;
}

#support-intro p {
  font-size: 1.2rem;
}

/* Nice centred loading text */
.wrapper .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  font-weight: bold;
  color: #fff;
  background: #000;
  border-radius: 5px;
  padding: 10px;
  padding-right: 30px;
  animation: padChange steps(4,end) 900ms infinite;
}

/* Following is an animated ellipsis */
.wrapper .loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 20px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;
  }
}

/* Also animate the padding, so the total box size doesn't change */
    @keyframes padChange {
      to {
        padding-right: 10px;
      }
    }

    @-webkit-keyframes padChange {
      to {
        padding-right: 10px;
      }
    }

/* Hide the default loading spinner */
#bbox-root >>> #bbox-msg-wrapper {
  display: none;
}


/* Nicely centres everything */
>>> #mongo-form {
  display: flex;
}

>>> #bboxdonation_BBEmbeddedForm {
  border: 1px solid #212121;
  border-radius: 16px;
  padding-top: 0;
  background-color: rgba(66, 66, 66, 0.5);
  box-shadow: 1px 1px 0 1px rgba(0, 0, 0, 0.2);
  margin-bottom: 16px;
}

/* Better colour on the links */
>>> a {
  color: rgb(0,187,212);
}

/* This centres the donation amounts */
>>> #bboxdonation_gift_rdlstGivingLevels {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

/* This shapes the donation amount buttons to make them consistent with other buttons on the site */
>>> .BBFormRadioButtonContainer label.BBFormRadioLabel {
  border-radius: 28px;
}

  /* This makes the "Other" amount nicely centred in its box */
>>> .BBFormRadioButtonContainer label.BBFormRadioLabel input.BBFormTextbox {
  margin-top: -12px;
  border-radius: 14px;
}

  /* Better text colour for non-headings */
>>> .BBFormFieldContainer label,
>>> .BBFormFieldContainer > span,
>>> span.BBFormCheckbox {
  color: rgb(199,199,199);
}

/* These next 3 centre the secure payment button */
>>> .BBFormSection.BBFormButtonRow {
  display: flex;
  justify-content: center;
}
>>> .BBFormSection.BBFormButtonRow .BBFormFieldContainer {
  flex: 0 0 0;
}
>>> .BBFormSection.BBFormButtonRow label.BBFormFieldLabel {
  padding: 0;
}

/* This styles the secure payment button */
>>> .BBFormSection .BBFormFieldContainer input.BBFormSubmitbutton {
  border-radius: 28px;
  box-shadow: none;
}
</style>
