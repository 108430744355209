<template lang="html">
  <v-container>
    <v-layout row justify-center>
      <v-dialog v-model="dialog" scrollable>
        <template v-slot:activator="{ on }">
          <v-btn class="px-5 py-3" round color="cyan" v-on="on">
            <v-icon style="font-size:16px">pin_drop</v-icon>
            Change Region
          </v-btn>
        </template>
        <v-card>
          <v-card-title>Select Region</v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 300px;">
            <region-select></region-select>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="blue darken-1" flat @click="dialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
import RegionSelect from './RegionSelect.vue'
export default {

  data(){
    return {
      dialog: false
    }
  },

  components: {
    RegionSelect
  }
}
</script>

<style lang="css" scoped>
</style>
