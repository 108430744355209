<template lang="html">
  <v-footer
    :class="{
      'pa-4': $vuetify.breakpoint.mdAndUp,
      'pt-1': $vuetify.breakpoint.smAndDown,
    }"
    :height="footer_height"
  >
    <v-layout row wrap align-center>
      <v-flex md6 xs12>
        <div
          :class="{
            'text-xs-left body-1 ml-3': $vuetify.breakpoint.mdAndUp,
            'text-xs-center body-1': $vuetify.breakpoint.smAndDown,
            'text-xs-left caption': $vuetify.breakpoint.lg,
          }"
        >
          <span class="key hidden-sm-and-down"
            >Graphics and lead scientist:&nbsp;</span
          >
          <span class="key hidden-md-and-up">Lead scientist :&nbsp;</span>
          <a
            target="_blank"
            @click="edLinkClicked"
            href="http://www.met.reading.ac.uk/~ed/home/index.php"
            >Ed Hawkins</a
          >
          <span class="value hidden-lg-and-down"
            >, National Centre for Atmospheric Science, University of
            Reading.</span
          >
          <span class="value hidden-lg-and-up">, NCAS, UoR.</span>
          <span class="value hidden-md-and-down"
            >, National Centre for Atmospheric Science, UoR.</span
          >
        </div>
        <div
          :class="{
            'text-xs-left ml-3': $vuetify.breakpoint.mdAndUp,
            'text-xs-center': $vuetify.breakpoint.smAndDown,
            'text-xs-left caption': $vuetify.breakpoint.lg,
          }"
        >
          <span class="key">Data:&nbsp;</span>
          <span class="value hidden-md-and-up"
            >Berkeley Earth &amp; ERA5-Land, NOAA, UK Met Office,<br />
            MeteoSwiss, DWD, SMHI, UoR &amp; ZAMG</span
          >
          <span class="value hidden-sm-and-down"
            >Berkeley Earth &amp; ERA5-Land, NOAA, UK Met Office, MeteoSwiss,
            DWD, SMHI, UoR &amp; ZAMG</span
          >
        </div>
      </v-flex>
      <v-flex md4 offset-md2 xs-12>
        <div
          :class="{
            'text-xs-right subheading mr-4': $vuetify.breakpoint.mdAndUp,
            'text-xs-center subheading': $vuetify.breakpoint.smAndDown,
            'text-xs-right caption': $vuetify.breakpoint.lg,
          }"
        >
          <span class="key">Website:&nbsp;</span>
          <a
            target="_blank"
            @click="ieaLinkClicked"
            href="https://www.the-iea.org/"
          >
            <img
              src="@/assets/IEA_favicon.png"
              class="iea_favicon"
              height="30"
              alt="Institute for Environmental Analytics logo"
            />
            <span class="hidden-sm-and-down"
              >Institute for Environmental Analytics</span
            >
            <span class="hidden-md-and-up">IEA</span>
          </a>
        </div>
      </v-flex>
    </v-layout>
  </v-footer>
</template>

<script>
export default {
  computed: {
    footer_height() {
      let height = ''
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
        case 'md':
          height = 130
          break
        case 'lg':
          height = 50
          break
        case 'xl':
          height = 70
          break
      }
      return height
    },
  },
  methods: {
    edLinkClicked() {
      plausible('Clicked Ed Homepage Link')
    },
    ieaLinkClicked() {
      plausible('Clicked IEA Link')
    },
  },
}
</script>

<style lang="css" scoped>
.iea_favicon {
  vertical-align: bottom;
}
.key {
  color: gray;
}
.value {
  color: white;
}
footer a {
  color: #c7f4ff;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}
</style>
