import Stripes from './components/Stripes.vue'
import Map from './components/Map.vue'
import Support from './components/Support.vue'
import Faq from './components/Faq.vue'
import Showcase from './components/Showcase.vue'

export const routes = [
  { path: '/showcase', name: 'showcase', component: Showcase },
  { path: '/map', name: 'map', component: Map },
  { path: '/faq', name: 'faq', component: Faq },
  { path: '/support', name: 'support', component: Support },
  {
    path: '/map',
    // path: '/map/:region?/:country?/:location?',
    name: 'map',
    component: Map,
  },
  {
    path: '/:stripeType?/:region?/:country?/:location?',
    name: 'stripes',
    component: Stripes,
  },
]
