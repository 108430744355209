var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{class:{
        'mt-5': _vm.$vuetify.breakpoint.mdAndUp,
        'mt-2': _vm.$vuetify.breakpoint.smAndDown,
      },attrs:{"mb-3":"","xs12":"","text-xs-center":""}},[_c('v-icon',{class:{
          heading: _vm.$vuetify.breakpoint.mdAndUp,
          smallHeading: _vm.$vuetify.breakpoint.smAndDown,
        }},[_vm._v(" question_answer ")]),_c('span',{class:{
          heading: _vm.$vuetify.breakpoint.mdAndUp,
          smallHeading: _vm.$vuetify.breakpoint.smAndDown,
        }},[_vm._v("FAQ ")]),_c('span',{staticClass:"heading ml-0 hidden-sm-and-down"},[_vm._v(" : Frequently asked questions ")])],1),_c('v-flex',{attrs:{"xs12":"","md8":""}},[_c('v-expansion-panel',{staticClass:"mb-4",attrs:{"expand":"","focusable":""},model:{value:(_vm.openState),callback:function ($$v) {_vm.openState=$$v},expression:"openState"}},_vm._l((_vm.data),function(item,i){return _c('v-expansion-panel-content',{key:i,scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{style:(_vm.faqQuestionStyles)},[_vm._v(" "+_vm._s(item.question)+" ")])]},proxy:true}],null,true)},[_c('v-card',{style:(_vm.faqAnswerStyles)},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("double_arrow")]),_c('span',{domProps:{"innerHTML":_vm._s(item.answer)}})],1)],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }