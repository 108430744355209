var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"color":"#363636"}},[_c('v-card-text',{staticClass:"pa-2 text-xs-center"},[_c('span',{class:{
        "d-inline": _vm.$vuetify.breakpoint.mdAndUp,
        "d-block caption": _vm.$vuetify.breakpoint.smAndDown
      }},[_vm._v(" Interested in studying weather and climate?  ")]),_c('a',{attrs:{"target":"_blank","href":"http://www.reading.ac.uk/met/"},on:{"click":_vm.linkClicked}},[_c('span',{class:{
          "d-inline": _vm.$vuetify.breakpoint.mdAndUp,
          "d-block caption": _vm.$vuetify.breakpoint.smAndDown
        }},[_vm._v(" Take a look at our courses at the "),_c('span',[_vm._v("University of Reading")]),_c('img',{staticClass:"ml-1",attrs:{"height":"15","src":require("@/assets/uor_favicon.png"),"alt":"University of Reading logo"}})])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }