<template lang="html">
  <div>
    <v-navigation-drawer
      app
      v-model="drawerOpen"
      v-if="$vuetify.breakpoint.mdAndDown"
    >
      <v-list three-line subheader>
        <v-subheader>Menu</v-subheader>
        <template v-for="item in menuItems">
          <v-list-tile
            :to="item.route ? item.route : ''"
            :href="item.target ? item.target : ''"
            :target="item.target ? '_blank' : ''"
            :key="item.name"
          >
            <v-list-tile-content>
              <v-list-tile-title>{{ item.title }}</v-list-tile-title>
              <v-list-tile-sub-title>{{ item.desc }}</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-divider :key="item.name"></v-divider>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-toolbar :height="nav_bar_height">
      <v-divider vertical v-if="$vuetify.breakpoint.lgAndUp"> </v-divider>
      <v-toolbar-side-icon
        v-if="$vuetify.breakpoint.smAndDown"
        @click="drawerOpen = !drawerOpen"
      >
      </v-toolbar-side-icon>
      <v-toolbar-title
        :class="{
          'ml-0': $vuetify.breakpoint.mdAndDown,
          'ml-3': $vuetify.breakpoint.lgAndUp,
        }"
      >
        <router-link
          class="font-weight-medium"
          :class="{
            smalltitle: $vuetify.breakpoint.mdAndDown,
            title: $vuetify.breakpoint.lgAndDown,
            'display-1': $vuetify.breakpoint.lgAndUp,
          }"
          to="/"
          tag="button"
        >
          {{ appTitle }}
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <template v-for="item in menuItems">
          <v-btn
            flat
            :to="item.route ? item.route : ''"
            :href="item.target ? item.target : ''"
            :target="item.target ? '_blank' : ''"
            :key="item.name"
            :class="{
              title: $vuetify.breakpoint.lgAndUp,
              subheading: $vuetify.breakpoint.lgAndDown,
              menuitem: $vuetify.breakpoint.mdAndDown,
            }"
          >
            {{ item.title }}
          </v-btn>
          <v-divider vertical :key="item.name"> </v-divider>
        </template>
      </v-toolbar-items>
      <a
        target="_blank"
        rel="noopener"
        @click="linkClicked"
        href="https://www.reading.ac.uk/planet/"
      >
        <img
          alt="University of Reading logo"
          class="mx-3 mt-2"
          src="@/assets/img/UoR_Logo.png"
          :width="$vuetify.breakpoint.lgAndUp ? 120 : 80"
        />
      </a>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  name: 'AppNavigation',
  methods: {
    linkClicked() {
      plausible('Clicked Rdg Header Link')
    },
  },
  data() {
    return {
      appTitle: '#ShowYourStripes',
      menuItems: [
        {
          title: 'Stripes',
          desc: 'Check warming trends of your country/city',
          route: '/',
        },
        {
          title: 'Map',
          desc: 'Explore warming stripes around the globe',
          route: '/map',
        },
        {
          title: 'FAQ',
          desc: 'Frequently asked Questions',
          route: '/faq',
        },
        {
          title: 'Showcase',
          desc: 'Showcase of various ways people have used the stripes',
          route: '/showcase',
        },
        {
          title: 'Get Involved',
          desc: 'Get Involved in Show Your Stripes Day',
          target:
            'https://www.reading.ac.uk/planet/events/show-your-stripes-day',
        },
        {
          title: 'Biodiversity',
          desc: 'A partner project applying the stripes concept to biodiversity',
          target: 'https://biodiversitystripes.info',
        },
        {
          title: 'Support us',
          desc: 'Support our work by making a donation to fund science and climate education',
          route: '/support',
        },
      ],
      drawerOpen: false,
    }
  },
  computed: {
    nav_bar_height() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
        case 'md':
        case 'lg':
          return 60
        case 'xl':
          return 80
        default:
          return 60
      }
    },
  },
}
</script>

<style lang="css" scoped>
.menuitem {
  font-size: 0.9rem !important;
  padding: 0;
}

.smalltitle {
  font-size: 1.2rem !important;
}
</style>
