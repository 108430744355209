import '@mdi/font/css/materialdesignicons.css'
import 'babel-polyfill'
import Vue from 'vue'

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import 'vuetify/src/stylus/app.styl'

import { Icon } from 'leaflet'
import marker from 'leaflet/dist/images/marker-icon.png'
import marker2x from 'leaflet/dist/images/marker-icon-2x.png'
import shadow from 'leaflet/dist/images/marker-shadow.png'

Vue.use(Vuetify, {
  iconfont: 'mdi',
})

import VueRouter from 'vue-router'
import App from './App.vue'
import { routes } from './routes.js'

delete Icon.Default.prototype._getIconUrl

Icon.Default.mergeOptions({
  iconRetinaUrl: marker2x,
  iconUrl: marker,
  shadowUrl: shadow,
})

Vue.use(VueRouter)

const router = new VueRouter({
  // base: process.env.NODE_ENV === 'production' ? '/showyourstripes/' : '/',
  routes: routes,
  mode: 'history',
})

Vue.config.productionTip = false

import menuTree from './data/regionData.js'

import Papa from 'papaparse'

const mapLocations = []
const findUrlInTree = (name, url, path, tree) => {
  // if (name.startsWith('San M') && tree.name && tree.name.startsWith('San M')) {
  //   console.log(tree.name, name, tree.name == name, tree.url.display_link, path)
  //   console.log('comparing', url, tree.url.display_link)
  // }

  if (tree && tree.url) {
    // Change url from e.g. /stripes/EUROPE-Germany-Nordrhein_Westfalen-1881-2022-DW.png to EUROPE-Germany-Nordrhein_Westfalen
    const stripeName = decodeURIComponent(
      tree.url.display_link.split('/')[2].split('-').slice(0, -3).join('-')
    ).normalize()
    if (url === stripeName) {
      return { path, details: tree }
    }
  }

  if (!tree.children) {
    return { path, details: null }
  }

  for (let child in tree.children) {
    const newTree = findUrlInTree(
      name,
      url,
      `${path}/${child}`,
      tree.children[child]
    )
    if (newTree.details) {
      return { path: newTree.path, details: newTree.details }
    }
  }

  return { path, details: null }
}

const fetchData = async () => {
  const rawData = await fetch(`/stripes/stripes_locations.csv`)
  const text = await rawData.text()
  const parsed = Papa.parse(text, {
    delimiter: ',',
    header: true,
    skipEmptyLines: true,
    // We want all fields to be numeric apart from location,
    // which should be forced to string
    // dynamicTyping: field => field !== 'location',
    transformHeader: (header) => header.trim(),
  })

  for (let row of parsed.data) {
    const name = row.Name.trim().normalize()
    const url = decodeURIComponent(row.URL.trim()).normalize()
    const lat = row.Lat
    const lon = row.Lon
    const { path, details } = findUrlInTree(name, url, '', {
      children: menuTree,
    })
    if (!details) {
      console.warn('Could not find details for', name)
    }
    mapLocations.push({
      name,
      location: [lat, lon],
      path,
      details,
    })
  }
}
fetchData()

Vue.prototype.$store = Vue.observable({
  menuTree,
  mapLocations,
  selectedStripeType: 's',
  selectedRegion: 'globe',
  selectedCountry: '',
  selectedLocation: '',
  zoom: 3,
  center: [30, 0],
  openMarker: null,
  firstMapRun: true,
})

new Vue({
  router: router,
  render: (h) => h(App),
}).$mount('#app')
