<template lang="html">
  <v-card
    color='#363636'
    >
    <v-card-text
      class="
        pa-2
        text-xs-center"
      >
      <span
        :class='{
          "d-inline": $vuetify.breakpoint.mdAndUp,
          "d-block caption": $vuetify.breakpoint.smAndDown
        }'
        >
        Interested in studying weather and climate?&nbsp;
      </span>
      <a
        target="_blank"
        @click="linkClicked"
        href="http://www.reading.ac.uk/met/"
        >
        <span
          :class='{
            "d-inline": $vuetify.breakpoint.mdAndUp,
            "d-block caption": $vuetify.breakpoint.smAndDown
          }'
          >
          Take a look at our courses at the
          <span>University of Reading</span>
          <img
            height="15"
            class="ml-1"
            src='@/assets/uor_favicon.png'
            alt="University of Reading logo"
          />
        </span>
      </a>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  methods: {
    linkClicked() {
      plausible('Clicked Study Met Link')
    }
  }
}
</script>

<style lang="css" scoped>
a {
  color: #c7f4ff;
}

img{
  position: relative;
  top: 2px;
}
</style>
