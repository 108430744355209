<template lang="html">
  <v-container>
    <v-layout wrap>
      <v-flex sm12>
        <v-select
          v-model="selectedRegion"
          :items="availableRegions"
          label="Region"
        ></v-select>
      </v-flex>
      <v-flex v-if="availableCountries.length" sm12>
        <v-select
          v-model="selectedCountry"
          :items="availableCountries"
          :label="selectedRegion === 'ocean' ? 'Basin' : 'Country'"
        ></v-select>
      </v-flex>
      <v-flex v-if="availableLocations.length" sm12>
        <v-select
          v-model="selectedLocation"
          :items="availableLocations"
          label="Location"
        ></v-select>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  computed: {
    availableRegions() {
      return Object.entries(this.$store.menuTree).map(e => ({
        value: e[0],
        text: e[1].name
      }))
    },
    availableCountries() {
      const regionObj = this.$store.menuTree[this.selectedRegion]
      if (!regionObj || !regionObj.hasOwnProperty('children')) {
        return []
      }
      return Object.entries(regionObj.children).map(e => ({
        value: e[0],
        text: e[1].name
      }))
    },
    availableLocations() {
      if (!this.selectedCountry) {
        return []
      }
      const regionObj = this.$store.menuTree[this.selectedRegion]
      if (!regionObj || !regionObj.hasOwnProperty('children')) {
        return []
      }
      const countryObj = regionObj.children[this.selectedCountry]
      if (!countryObj || !countryObj.hasOwnProperty('children')) {
        return []
      }

      return Object.entries(countryObj.children).map(e => ({
        value: e[0],
        text: e[1].name
      }))
    },
    selectedRegion: {
      get() {
        return this.$store.selectedRegion
      },
      set(region) {
        this.$store.selectedRegion = region
      }
    },
    selectedCountry: {
      get() {
        return this.$store.selectedCountry
      },
      set(country) {
        this.$store.selectedCountry = country
      }
    },
    selectedLocation: {
      get() {
        return this.$store.selectedLocation
      },
      set(location) {
        this.$store.selectedLocation = location
      }
    }
  },
  methods: {
    loadStripes() {
      const params = {
        stripeType: this.$store.selectedStripeType || 's'
      }
      if (this.selectedRegion) {
        params.region = this.selectedRegion
      }
      if (this.selectedCountry) {
        params.country = this.selectedCountry
      }
      if (this.selectedLocation) {
        params.location = this.selectedLocation
      }
      this.$router.push({ name: 'stripes', params })
    }
  },
  watch: {
    selectedRegion() {
      const regionObj = this.$store.menuTree[this.selectedRegion]

      if (regionObj.hasOwnProperty('children')) {
        if (regionObj.children.hasOwnProperty('all')) {
          this.selectedCountry = 'all'
          // We want to show all countries in this region, so an endpoint has been
          // reached
        } else {
          // There are countries in this region, but we need to select one - endpoint
          // not reached
          this.selectedCountry = Object.keys(regionObj.children)[0]
        }
      } else {
        // There are no countries in this region, so an endpoint has been reached
        this.selectedCountry = ''
      }
      // Whether or not there are countries in this region, we haven't selected a
      // location, since this is one level below a country
      this.selectedLocation = ''

      // Go to a new route
      this.loadStripes()
    },
    selectedCountry() {
      if (!this.selectedCountry) {
        this.selectedLocation = ''
        return
      }

      const countryObj = this.$store.menuTree[this.selectedRegion].children[
        this.selectedCountry
      ]

      if (countryObj.hasOwnProperty('children')) {
        if (countryObj.children.hasOwnProperty('all')) {
          // We want to show all locations in this country, so and endpoint has
          // been reached
          this.selectedLocation = 'all'
        } else {
          // There are locations in this country, but we need to select one.
          // Choose the first available, and allow the user to change it if they want
          this.selectedLocation = Object.keys(countryObj.children)[0]
        }
      } else {
        // There are no locations in this country, so an endpoint has been reached
        this.selectedLocation = ''
      }

      // Go to a new route
      this.loadStripes()
    },
    selectedLocation() {
      if (!this.selectedLocation) {
        return
      }
      // This will always be an endpoint
      this.loadStripes()
    }
  }
}
</script>

<style lang="css" scoped></style>
