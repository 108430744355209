<template lang="html">
  <div class="top-container">
    <v-tabs slider-color="cyan" color="#202020" centered v-model="selectedTab">
      <v-tab
        v-for="imgType in imgTypeObj"
        :key="imgType.id"
        :link="true"
        :href="`#${imgType.id}`"
      >
        {{ imgType.tabText }}
      </v-tab>

      <v-tab-item
        v-for="imgType in imgTypeObj"
        :key="imgType.tabText"
        :value="imgType.id"
        transition="slide-x-transition"
        reverse-transition="slide-x-transition"
      >
        <div class="stripe-container">
          <img
            class="stripe-image"
            :src="imgType.imgSource"
            :alt="`warming strip image for ${imageSettings.name}`"
          />
          <div class="caption text-xs-center hidden-md-and-up">
            This work is licensed under CC BY 4.0
            <a
              target="_blank"
              rel="license"
              href="http://creativecommons.org/licenses/by/4.0/"
            >
              <img
                class="mt-2"
                alt="Creative Commons Licence"
                style="border-width: 0"
                src="https://i.creativecommons.org/l/by/4.0/80x15.png"
              />
            </a>
          </div>
          <div class="download-button-styles">
            <v-tooltip top v-if="sharing">
              <template #activator="{ on }">
                <v-btn
                  :small="$vuetify.breakpoint.xsOnly"
                  color="cyan"
                  fab
                  v-on="on"
                  @click="shareFacebook()"
                >
                  <v-icon>facebook</v-icon>
                </v-btn>
              </template>
              <span class="tool-tip">Share to facebook</span>
            </v-tooltip>
            <v-tooltip top v-if="sharing">
              <template #activator="{ on }">
                <v-btn
                  :small="$vuetify.breakpoint.xsOnly"
                  color="cyan"
                  fab
                  v-on="on"
                  @click="shareTwitter()"
                >
                  <v-icon>mdi-twitter</v-icon>
                </v-btn>
              </template>
              <span class="tool-tip">Share to twitter</span>
            </v-tooltip>
            <v-tooltip top v-if="sharing">
              <template #activator="{ on }">
                <v-btn
                  :small="$vuetify.breakpoint.xsOnly"
                  color="cyan"
                  fab
                  v-on="on"
                  @click="shareLinkedIn()"
                >
                  <v-icon>mdi-linkedin</v-icon>
                </v-btn>
              </template>
              <span class="tool-tip">Share to LinkedIn</span>
            </v-tooltip>
            <v-tooltip top v-if="sharing">
              <template #activator="{ on }">
                <v-btn
                  :small="$vuetify.breakpoint.xsOnly"
                  color="cyan"
                  fab
                  v-on="on"
                  @click="downloadImage(imgType.imgSource)"
                >
                  <v-icon>file_download</v-icon>
                </v-btn>
              </template>
              <span class="tool-tip">Download</span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  :small="$vuetify.breakpoint.xsOnly"
                  color="cyan"
                  fab
                  v-on="on"
                  @click="share()"
                >
                  <v-icon>{{ sharing ? 'mdi-close' : 'mdi-menu' }}</v-icon>
                </v-btn>
              </template>
              <span class="tool-tip">{{
                sharing ? 'Hide options' : 'Options'
              }}</span>
            </v-tooltip>
          </div>
        </div>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
export default {
  name: 'DisplayStripes',
  props: {
    imageSettings: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      alertVisible: false,
      sharing: false,
    }
  },
  methods: {
    downloadImage(url) {
      this.dialog = false
      const link = document.createElement('a')
      link.href = url
      // Make the filename just the final part of the URL (don't need the `/stripes/` part)
      const filename = url.split('/').pop()
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      plausible('Download', { props: { filename } })
    },
    share() {
      this.sharing = !this.sharing
    },
    shareFacebook() {
      const link = document.createElement('a')
      const url = encodeURIComponent(window.location.href)
      link.href = `https://www.facebook.com/sharer/sharer.php?u=${url}`
      link.target = '_blank'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      plausible('Share on Facebook')
      this.sharing = false
    },
    shareTwitter() {
      const link = document.createElement('a')
      const url = encodeURIComponent(window.location.href)
      link.href = `https://twitter.com/intent/tweet?url=${url}`
      link.target = '_blank'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      plausible('Share on Twitter')
      this.sharing = false
    },
    shareLinkedIn() {
      const link = document.createElement('a')
      const url = encodeURIComponent(window.location.href)
      link.href = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`
      link.target = '_blank'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      plausible('Share on LinkedIn')
      this.sharing = false
    },
  },
  computed: {
    selectedTab: {
      set(tab) {
        this.$store.selectedStripeType = tab
        this.$router.push({
          name: 'stripes',
          params: {
            stripeType: this.$store.selectedStripeType,
            region: this.$route.params.region,
            country: this.$route.params.country,
            location: this.$route.params.location,
          },
        })
      },
      get() {
        return this.$store.selectedStripeType || 's'
      },
    },
    strippedName() {
      const pattern = /<All\sof\s([\w+\s]+)>/g
      return pattern.test(this.imageSettings.name)
        ? this.imageSettings.name.replace(pattern, '$1')
        : this.imageSettings.name
    },
    imgTypeObj() {
      return [
        {
          tabText: 'Warming stripes',
          id: 's',
          imgSource: this.imageSettings.url.display_link,
        },
        {
          tabText: 'Labelled stripes',
          id: 'l',
          imgSource: this.imageSettings.url.download_link_label,
        },
        {
          tabText: 'Bars',
          id: 'b',
          imgSource: this.imageSettings.url.download_link_bar,
        },
        {
          tabText: 'Bars with scale',
          id: 'c',
          imgSource: this.imageSettings.url.download_link_bar_labels,
        },
      ]
    },
  },
}
</script>

<style lang="css" scoped>
.top-container {
  max-width: 1000px;
  width: 100%;
}

.stripe-container {
  background-color: #222;
  position: relative;
  border-radius: 5px;
  border: 5px double #535353;
  /* To get rid of the inexplicable gap at the bottom, probably introduced by vuetify */
  padding: 10px 10px 2px 10px;
}

.stripe-container p {
  background: rgba(0, 0, 0, 0.6);
  padding: 15px;
  position: absolute;
  width: calc(100% - 20px);
  margin: 0;
  text-align: center;
  font-weight: 500;
}

.stripe-container p.stripe-caption {
  font-size: 14px;
}
.stripe-container p.stripe-title {
  font-size: 18px;
}

.stripe-container .stripe-image {
  width: 100%;
  margin: 0;
  padding: 0;
  min-width: 310px;
}

.tool-tip {
  font-size: 16px;
}
.download-button-styles {
  position: absolute;
  bottom: 58px;
  right: 35px;
}

@media (max-width: 545px) {
  >>> .v-tabs__item {
    font-size: 0.85em;
  }
}

@media (max-width: 480px) {
  >>> .v-tabs__item {
    font-size: 0.75em;
  }
}

@media (max-width: 435px) {
  >>> .v-tabs__item {
    font-size: 0.7em;
  }
}

@media (max-width: 415px) {
  >>> .v-tabs__item {
    font-size: 0.62em;
  }
}
</style>
