<template lang="html">
  <div class="customContainer">
    <div class="leftPanel hidden-sm-and-down">
      <v-card class="cardBody mb-4">
        <v-card-title class="cardTitle title">
          <v-icon>pin_drop</v-icon>
          <span class="ml-2">Select Region</span>
        </v-card-title>
        <v-divider></v-divider>
        <region-select @endPointReached="regionSelected"></region-select>
      </v-card>

      <v-card class="cardBody" v-if="showStripes">
        <v-card-title class="cardTitle title">
          <v-icon>info</v-icon>
          <span class="ml-2">Information</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <table>
            <tbody>
              <tr>
                <td class="fieldTitle">Region</td>
                <td>{{ imageInfo.name }}</td>
              </tr>
              <tr>
                <td class="fieldTitle">Date Range</td>
                <td>
                  {{ imageInfo.timeRange.from }}-{{ imageInfo.timeRange.to }}
                </td>
              </tr>
              <tr>
                <td class="fieldTitle">Data Source</td>
                <td>{{ data_source_dictionary }}</td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
        <v-card-text>
          <table>
            <tbody>
              <tr>
                <td class="fieldTitle">Creator</td>
                <td>
                  <a
                    target="_blank"
                    href="http://www.met.reading.ac.uk/~ed/home/index.php"
                  >
                    Ed Hawkins
                  </a>
                </td>
              </tr>
              <tr>
                <td class="fieldTitle">Licensor</td>
                <td>University of Reading</td>
              </tr>
              <tr>
                <td class="fieldTitle">License</td>
                <td>
                  <a
                    id="licenseLogo"
                    target="_blank"
                    rel="license"
                    href="https://creativecommons.org/licenses/by/4.0/"
                  >
                    <img
                      alt="Creative Commons Licence"
                      style="border-width: 0"
                      src="https://i.creativecommons.org/l/by/4.0/88x31.png"
                    />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>
    </div>

    <div class="rightPanel">
      <display-stripes
        v-if="showStripes"
        :imageSettings="imageInfo"
      ></display-stripes>
      <img v-else class="missing" src="@/assets/missing.png" />
      <region-select-small
        @endPointReached="regionSelected"
        class="hidden-md-and-up"
      ></region-select-small>
    </div>
  </div>
</template>

<script>
import RegionSelect from './RegionSelect.vue'
import RegionSelectSmall from './RegionSelectSmall.vue'
import DisplayStripes from './DisplayStripes.vue'

export default {
  data() {
    return {
      // waits for the emit
      display_information_box: false,
      media: {},
    }
  },

  computed: {
    data_source_dictionary: function () {
      //Tue, 4 Jun 2019 22:04:48 +0100
      switch (this.imageInfo.data_source.split('.')[0]) {
        case 'BK':
          return 'Berkeley Earth & ERA5-Land'
        case 'MO':
          return 'UK Met Office'
        case 'NO':
          return 'NOAA'
        case 'MS':
          return 'MeteoSwiss'
        case 'DW':
          return 'DWD'
        case 'BO':
          return 'Bolin Centre'
        case 'ZA':
          return 'ZAMG'
        case 'MF':
          return 'Meteo-France'
        case 'UR':
          return 'University of Reading'
        default:
          return ''
      }
    },
    imageInfo() {
      const region = this.$route.params.region
        ? this.$route.params.region
        : 'globe'
      const regionObj = this.$store.menuTree[region]
      if (!regionObj) {
        // Menu tree probably hasn't loaded yet
        return {}
      }

      const country = this.$route.params.country
      if (country && regionObj.hasOwnProperty('children')) {
        const countryObj = regionObj.children[country]
        const location = this.$route.params.location

        if (location && countryObj.hasOwnProperty('children')) {
          const locationObj = countryObj.children[location]
          return locationObj
        } else {
          return countryObj
        }
      } else {
        return regionObj
      }
    },
    showStripes() {
      return this.imageInfo && this.imageInfo.hasOwnProperty('url')
    },
  },
  methods: {
    regionSelected(media) {
      this.display_information_box = media.display
      this.media = media
    },
  },
  components: {
    RegionSelect,
    RegionSelectSmall,
    DisplayStripes,
  },
  created() {
    console.log('Stripes component created')
  },
}
</script>

<style lang="css" scoped>
.customContainer {
  width: 100%;
  margin: auto 0;
  display: flex;
  padding: 7vh 0;
}
.leftPanel {
  flex: 3;
  padding: 0 2vw 0 5vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.rightPanel {
  flex: 7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 5vw 0 2vw;
}

.rightPanel img.missing {
  width: 100%;
}

.cardTitle {
  background-color: #202020;
}
.cardBody {
  max-width: 420px;
  background-color: #292929;
}
.fieldTitle {
  width: 120px;
}
table {
  width: 100%;
}
td {
  border: 1px solid #535353;
  padding: 3px 12px;
}
.tableTitle {
  text-align: center;
  margin-bottom: 3px;
  font-weight: bold;
  font-size: 15px;
}
a {
  color: #c7f4ff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
